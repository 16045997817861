




































import constants from '@/constants'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ComplianceText extends Vue {
  onLinkClick() {
    this.$modal.show(constants.modalNames.COMMERCIAL_TRANSACTIONS_MODAL)
  }
}
