









































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import TextInput from '@/components/TextInput.vue'
import { FactDescription } from '@/interfaces/engine-exports'
import { FactConfig } from '@/services/Renderer'
import { Shift } from '@/services/Shift'
import AonButton from '@/components/AonButton.vue'

@Component({
  components: {
    AonButton,
    TextInput
  }
})
export default class CampaignCodeRenderer extends Vue {
  @Prop() fact!: FactDescription
  @Prop() config?: FactConfig
  @Prop({ default: '' }) planType!: string
  @Prop({ default: '' }) conditionType!: string
  @Prop({ default: false }) campaignCodeSet!: boolean

  private errorMessage = ''

  private currentValue = ''

  created() {
    this.currentValue = this.fact.currentValue
  }

  get label(): string {
    if (this.config?.label) {
      return this.$t(this.config.label).toString()
    } else {
      return this.fact.name
    }
  }

  onInput(value: string) {
    this.updateValue(value)
  }

  onBlur(value: string) {
    this.updateValue(value.toUpperCase())
  }

  updateValue(value: string) {
    this.currentValue = value
    const valid = this.currentValue === '' || this.currentValue.match(/^[a-zA-Z0-9]{7,10}$/) !== null
    this.errorMessage = valid ? '' : this.$t('summary.campaignCode.INVALID').toString()
    this.fireCampaignCodeChange({ campaignCode: this.currentValue, discountAmount: '0', valid: this.currentValue === '' })
  }

  private async checkCampaignCode() {
    const result = await Shift.checkCampaignCode(this.planType, this.conditionType, this.currentValue)
    if (result.startsWith('INVALID') || result === 'EXPIRED') {
      this.currentValue = ''
      this.errorMessage = this.$t(`summary.campaignCode.${result}`).toString()
      this.fireCampaignCodeChange({ campaignCode: this.currentValue, discountAmount: '0', valid: true })
    } else {
      this.errorMessage = ''
      this.fireCampaignCodeChange({ campaignCode: this.currentValue, discountAmount: result, valid: true })
    }
  }

  private resetCampaignCode() {
    this.updateValue('')
  }

  @Emit('campaign-code-change')
  fireCampaignCodeChange(evt: { campaignCode: string, discountAmount: string, valid: boolean }) {
    return evt
  }
}
