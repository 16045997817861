


























































import { Component, Vue } from 'vue-property-decorator'
import Fact from '@/components/facts/Fact.vue'
import { Action, Getter } from 'vuex-class'
import { EventBus, OverlayGroup } from '@uncharted/coverhub-framework'
import { FnOverlayGroupByName, FnValidationErrors } from '@/store/modules/product/getters'
import store from '@/store'
import { Shift } from '@/services/Shift'
import { ACTION_FACT_VALUE_SET, ACTION_SET_INVOICE_ID, ACTION_SET_SALE_JOURNEY_STATE_INVOICE_AVAILABLE, ACTION_SET_SALE_JOURNEY_STATE_PAYMENT_STARTED, ACTION_SET_SALE_JOURNEY_STATE_PROPOSAL_SUBMITTED } from '@/store/modules/product/actionTypes'
import constants from '@/constants'
import { ACTION_SET_POLICY_HOLDER } from '@/store/modules/user/actionTypes'
import { POLLING_NAMES } from '@/constants/polling'
import CampaignCodeRenderer from '@/components/facts/renderers/CampaignCodeRenderer.vue'
import ComplianceText from '@/components/ComplianceText.vue'
import { FactValueChange } from '@/store/api/models/FactValueChange'
import { Config } from '@/config'
import { saleJourneyActionHandler, SaleJourneyActionType, saleJourneyRouteGuard } from '@/services/SaleJourneyService'
import { ConvertQuoteResponseModel } from 'shift-policy-service-api-client'

@Component({
  name: 'Summary',
  components: {
    CampaignCodeRenderer,
    ComplianceText,
    Fact
  }
})
export default class Summary extends Vue {
  @Getter('product/getValidationErrors')
  private getValidationErrors!: FnValidationErrors

  @Getter('product/getOverlayGroupByName')
  private getOverlayGroupByName!: FnOverlayGroupByName

  @Action(ACTION_SET_POLICY_HOLDER)
  setPolicyHolder!: () => void

  @Action(ACTION_FACT_VALUE_SET)
  setFactValue!: (fact: FactValueChange) => void

  get isValidCoverageForm(): boolean {
    return this.getValidationErrors().length > 0
  }

  private overlayGroup!: OverlayGroup
  private mappedFacts: any = {}
  private buttonsDisabled = false
  private nextDisabled = false
  private planType: string | undefined = ''
  private conditionType: string | undefined = ''
  private campaignCodeSet = false
  private priceBeforeConvert = -1

  renderer(factId: string): string {
    switch (factId) {
      case 'COMPONENT:base.coverage.devicetype':
        return 'DeviceTypeRenderer'
      default:
        return 'DetailFieldRenderer'
    }
  }

  private validateAndSubmit = async () => {
    const deviceSerialNumber = this.mappedFacts['summary.device'].find((f: any) => f.id === Config.ocr.factOcrMap.consoleSerialNum)?.currentValue
    if (!deviceSerialNumber) {
      throw Error('Somehow form was valid but device serial number not present.')
    }

    if (await Shift.duplicateBodySerialExistsForAccount(deviceSerialNumber)) {
      console.error('summary page: duplicate serial number')
      const msg = this.$t('common.error.duplicateBodySerial').toString()
      this.$showErrorPopUp(msg, () => {
        this.setFactValue({ groupName: 'application', factId: Config.ocr.factOcrMap.consoleSerialNum, value: '' })
        this.$router.replace({ name: constants.routeNames.APPLICATION_FORM })
      })
    } else {
      this.submitApplication()
    }
  }

  stateHandling(action: string) {
    switch (action) {
      case SaleJourneyActionType.NORMAL:
        this.submitApplication()
        break
      case SaleJourneyActionType.RESUBMIT_POLICY:
        console.info('sale journey - resubmit')
        this.submitApplication()
        break
      case SaleJourneyActionType.CONVERT_QUOTE:
        if (this.$store.state.product.convertQuote) {
          Shift.convertQuotes(this.$store.state.product.convertQuote)
            .then(async (response) => this.afterConvertHandling(response))
            .catch(async (error) => this.convertQuoteErrorHandling(error))
        } else {
          this.$router.push('/error?error=' + encodeURIComponent('unhandle sale journey - convert quote'))
        }
        console.info('sale journey - convert quote')
        break
      case SaleJourneyActionType.POLL_BUNDLE:
        console.log('sale journey - poll bundle')
        if (this.$store.state.product.bundle.id) {
          EventBus.emit(POLLING_NAMES.START)
          setTimeout(() => {
            this.waitForBundle(this.$store.state.product.bundle.id, 30000)
          }, 500)
        } else {
          this.$router.push('/error?error=' + encodeURIComponent('unhandle sale journey - poll bundle'))
        }
        break
      case SaleJourneyActionType.READY_MAKE_PAYMENT:
        console.log('sale journey - ready make payment')
        if (this.$store.state.product.invoiceId) {
          this.$router.push({
            name: constants.routeNames.APPLICATION_PAYMENT,
            query: { invoiceId: this.$store.state.product.invoiceId }
          })
        } else {
          this.$router.push('/error?error=' + encodeURIComponent('unhandle sale journey - ready make payment'))
        }
        break
      case SaleJourneyActionType.TO_CONFIRM_PAYMENT:
        console.log('sale journey - to confirm payment')
        if (this.$store.state.product.invoiceId) {
          this.$router.push({
            name: constants.routeNames.APPLICATION_PAYMENT,
            query: { invoiceId: this.$store.state.product.invoiceId }
          })
        } else {
          this.$router.push('/error?error=' + encodeURIComponent('unhandle sale journey - to confirm payment'))
        }
        break
      case SaleJourneyActionType.COMPLETED:
        console.log('sale journey - completed')
        break
      default:
        this.$router.push('/error?error=' + encodeURIComponent('unhandle sale journey'))
        break
    }
  }

  created() {
    this.overlayGroup = this.getOverlayGroupByName('summary')
    this.updateValues()
  }

  mounted() {
    // handle affiliate ID
    this.setFactValue({ groupName: 'application', factId: 'applicant.affiliateid', value: this.$store.state.app.affId })

    this.priceBeforeConvert = this.overlayGroup.overlays[0].getProducts()[0].calculatePrice().totalPriceInclTax

    const currentFacts = this.overlayGroup.overlays[0]
      .getProducts()[0]
      .describeFacts()
      .components[0].facts

    this.planType = currentFacts
      .find((f) => f.id === 'COMPONENT:base.plan.plan')?.currentValue
    this.conditionType = currentFacts
      .find((f) => f.id === 'COMPONENT:base.deviceregistration.intconditiontype')?.currentValue

    const currentCampaignCode = currentFacts
      .find((f) => f.id === 'COMPONENT:base.deviceregistration.campaigncode')?.currentValue
    this.campaignCodeSet = currentCampaignCode !== undefined && currentCampaignCode !== ''

    // Sale Journey Route Guard - to handle back button state
    const action = saleJourneyActionHandler(this.$store.state.product.saleJourneyState)
    if (action !== SaleJourneyActionType.NORMAL || action === SaleJourneyActionType.RESUBMIT_POLICY) {
      this.stateHandling(action)
    } else {
      if (this.$refs?.applyButton) { (this.$refs.applyButton as any).reset() }
      this.buttonsDisabled = false
      saleJourneyRouteGuard(action, this.$router, this.$store.state.product.invoiceId)
    }
  }

  private async onCampaignCodeChange(evt: { campaignCode: string, discountAmount: string, valid: string }) {
    this.nextDisabled = !evt.valid
    if (evt.valid) {
      this.setFactValue({ groupName: 'summary', factId: 'COMPONENT:base.deviceregistration.campaigncode', value: evt.campaignCode })
      this.setFactValue({ groupName: 'summary', factId: 'COMPONENT:base.deviceregistration.discountamount', value: evt.discountAmount })
      this.campaignCodeSet = evt.campaignCode !== ''
    } else {
      this.setFactValue({ groupName: 'summary', factId: 'COMPONENT:base.deviceregistration.campaigncode', value: '' })
      this.setFactValue({ groupName: 'summary', factId: 'COMPONENT:base.deviceregistration.discountamount', value: '0' })
      this.campaignCodeSet = false
    }
    this.overlayGroup.overlays[0].describeFacts()
    this.updateValues()
  }

  private updateValues() {
    // Cache facts in a map, since describeFacts triggers an infinite
    // render loop
    this.overlayGroup.overlays.forEach((o) => {
      this.mappedFacts[o.name] = o.describeFacts().allFacts
    })
  }

  private async convertQuoteErrorHandling (error: any) {
    console.error(error)
    EventBus.emit((POLLING_NAMES.END))
    await this.$router.push('/error?error=' + encodeURIComponent('error during convert'))
  }

  private async afterConvertHandling (convertQuoteModel: ConvertQuoteResponseModel) {
    const bundle = convertQuoteModel.bundle
    setTimeout(() => {
      this.waitForBundle(bundle.id, 30000)
    }, 500)
  }

  private async submitApplication() {
    this.buttonsDisabled = true

    if (store.state.product.sales && !store.state.product.sales.cart.isEmpty) {
      const errors = this.getValidationErrors().length > 0
      if (!errors) {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const config = store.state.product.serializedConfig!
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const productId = store.state.product.productModel?.id!

        if (config && productId) {
          EventBus.emit(POLLING_NAMES.START)
          this.priceBeforeConvert = this.overlayGroup.overlays[0].getProducts()[0].calculatePrice().totalPriceInclTax
          await store.dispatch(ACTION_SET_SALE_JOURNEY_STATE_PROPOSAL_SUBMITTED, true)
          await Shift.createPolicy(config, productId)
            .then(async (response) => this.afterConvertHandling(response))
            .catch(async (error) => this.convertQuoteErrorHandling(error))
        }
      } else {
        console.info(this.getValidationErrors())
        console.error('Somehow got to submit with invalid products.', this.getValidationErrors())
        await this.$router.push('/error?error=' + encodeURIComponent('validation error'))
      }
    } else {
      console.error('Somehow got to submit empty cart')
      await this.$router.push('/error?error=' + encodeURIComponent('cart was empty'))
    }
  }

  private async waitForBundle(policyBundleId: string, remainingTime: number) {
    try {
      const bundle = await Shift.getPolicyBundle(policyBundleId)
      if (bundle.status !== 'COMPLETE') {
        if (remainingTime <= 0) {
          EventBus.emit(POLLING_NAMES.END)
          await this.$router.push(
            '/error?error=' + encodeURIComponent('Bundle timed out.')
          )
        } else {
          setTimeout(() => {
            this.waitForBundle(bundle.id, remainingTime - 1000)
          }, 1000)
        }
      } else {
        const invoice = await Shift.getInvoice(bundle.invoiceId)
        if (invoice?.totalAmount !== this.priceBeforeConvert) {
          this.$showErrorPopUp(this.$t('summary.priceChange', { price: invoice?.totalAmount }).toString())
        }

        EventBus.emit(POLLING_NAMES.END)
        await this.setPolicyHolder()
        await store.dispatch(ACTION_SET_SALE_JOURNEY_STATE_INVOICE_AVAILABLE, true)
        store.dispatch(ACTION_SET_INVOICE_ID, bundle.invoiceId).then(() => {
          this.$router.push({
            name: constants.routeNames.APPLICATION_PAYMENT,
            query: { invoiceId: bundle.invoiceId }
          })
          store.dispatch(ACTION_SET_SALE_JOURNEY_STATE_PAYMENT_STARTED, true)
        })
      }
    } catch (e) {
      if (remainingTime <= 0) {
        await this.$router.push(
          '/error?error=' + encodeURIComponent('Bundle timed out.')
        )
      } else {
        setTimeout(() => {
          this.waitForBundle(policyBundleId, remainingTime - 1000)
        }, 1000)
      }
    }
  }
}
